import React, { useState, useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "./components/NavBar";
import Banner from "./components/Banner";
// import  Skills  from "./components/Skills";
import Portfolio from "./components/Portfolio";
// import  Contact  from "./components/Contact";
import Footer from "./components/Footer";
import CookieAlert from "./components/CookieAlert";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import posthog from "posthog-js";
import { useCookies } from "react-cookie";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Posthog
posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOSTs,
});

// Initialize Firebase/Google Analytics
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
function hello() {
  logEvent(analytics, "test_event", { description: "this is a test" });
}

function App() {
  const [animateContact, setAnimateContact] = useState(false);
  const [cookies, setCookies] = useCookies(["cookieConsent"]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
      <div className={`site-loader ${loading ? "" : "hidden"}`}>
        <NavBar setAnimateContact={setAnimateContact} />
        <Banner animateContact={animateContact} />
        <Portfolio />
        <Footer />
        {!cookies.cookieConsent && <CookieAlert setCookies={setCookies} />}
      </div>
    </div>
  );
}

export default App;
