import React from "react";

export default function CookieAlert({ setCookies }) {
  const giveCookieConsent = () => {
    setCookies("cookieConsent", true, { path: "/" });
  };

  return (
    <>
      <div className="cookie-consent-banner">
        <p className="cookie-message">
          We use cookies to enhance your experience. By continuing to visit this
          site, you agree to our use of cookies.
        </p>
        <button className="accept-cookie-btn" onClick={giveCookieConsent}>Accept Cookies</button>
      </div>
    </>
  );
}